import { Component, Input } from '@angular/core';
import { OpsparingPoliceDetaljer } from 'ds-api';

@Component({
  selector: 'mitpfa-savings-policy-details-marketrate-adm',
  templateUrl: './marketrate-adm.component.html',
  styleUrls: ['./marketrate-adm.component.scss']
})
export class MarketrateAdmComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() isCommissionAndRemuneration: boolean;

  expanded: boolean;

  toggleExpanded(expanded: boolean): void {
    this.expanded = expanded;
  }
}
