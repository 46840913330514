import { Component, Input } from '@angular/core';
import { OpsparingPoliceDetaljer } from 'ds-api';

@Component({
  selector: 'mitpfa-savings-policy-details-misc',
  templateUrl: './savings-policy-details-misc.component.html'
})
export class SavingsPolicyDetailsMiscComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() language: 'da' | 'en';
}
