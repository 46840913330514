import { Component, Input } from '@angular/core';
import { OpsparingPoliceDetaljer, Overfoersel } from 'ds-api';
import { PopupStyle } from '@pfa/models';
import { MatDialog } from '@angular/material/dialog';
import { MarketrateTransfersPopupComponent } from '../marketrate-transfers-popup/marketrate-transfers-popup.component';
import { SavingsTransferData } from '../marketrate-transfers-popup/marketrate-transfers-popup.model';

@Component({
  selector: 'mitpfa-savings-policy-details-marketrate-transfers',
  templateUrl: './marketrate-transfers.component.html'
})
export class MarketrateTransfersComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() chosenYear: number;

  constructor(private readonly dialog: MatDialog) {}

  showDetails(transfer: Overfoersel): void {
    this.dialog.open(MarketrateTransfersPopupComponent, {
      panelClass: PopupStyle.PopupMedium,
      data: {
        transfer,
        startYear: this.chosenYear
      } as SavingsTransferData
    });
  }
}
