import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PensionPlanCardListBuilder } from '../investment/pension-plan-card-list-builder';
import { ContentUtilService, PageTrackingService } from '@pfa/core';
import { takeUntil } from 'rxjs/operators';
import { OpsparingOversigt } from 'ds-api';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PensionPlanCard, PolicyContext } from '@pfaform';
import { InvestmentService, YouInvestService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss']
})
export class SavingsComponent implements OnInit, OnDestroy {
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly contentUtil: ContentUtilService = inject(ContentUtilService);
  private readonly youInvestService: YouInvestService =
    inject(YouInvestService);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly investmentService: InvestmentService =
    inject(InvestmentService);

  public pensionPlanCardList: PensionPlanCard[] = [];
  public savingsOverview: OpsparingOversigt;
  public policiesWithoutSavingsIds = new Array<string>();
  public showAdjust: boolean;
  private readonly unsubscribe = new Subject<void>();

  public ngOnInit(): void {
    window.scroll(0, 0);
    const pensionPlanCardListBuilder = new PensionPlanCardListBuilder(
      this.contentUtil,
      this.youInvestService,
      this.investmentService
    );

    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: { savingsOverview: OpsparingOversigt }) => {
        this.savingsOverview = data.savingsOverview;
        this.showAdjust =
          this.savingsOverview.policer.filter(policy => policy.detaljeMulig)
            .length > 0;

        // some policies are not available. Total amount is incorrect
        this.policiesWithoutSavingsIds = this.savingsOverview.policer
          .filter(policy => policy.opsparing === null)
          .map(savingsPolicy => savingsPolicy.id);

        this.pensionPlanCardList =
          pensionPlanCardListBuilder.createPensionPlanCardList(
            this.savingsOverview.policer.map(policy => ({
              ...policy,
              context: PolicyContext.Savings
            }))
          );

        this.pageTrackingService.trackContentLoaded();
      });
  }

  public pensionPlanCardSelected(policyId: string): void {
    const policy = this.savingsOverview.policer.find(
      savingsPolicy => savingsPolicy.id === policyId
    );
    if (policy) {
      if (policy.opsparing === null) {
        return;
      }
      this.router.navigate(['minopsparing', policyId]);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
