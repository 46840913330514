import { Component, Input } from '@angular/core';
import { OpsparingPoliceDetaljer } from 'ds-api';

@Component({
  selector: 'mitpfa-savings-policy-details-marketrate-misc',
  templateUrl: './marketrate-misc.component.html',
  styleUrls: ['./marketrate-misc.component.scss']
})
export class MarketrateMiscComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;

  expanded: boolean;

  toggleExpanded(expanded: boolean): void {
    this.expanded = expanded;
  }
}
