import { Component, Input } from '@angular/core';
import { OpsparingPoliceDetaljer } from 'ds-api';

@Component({
  selector: 'mitpfa-savings-policy-key-numbers',
  templateUrl: './savings-policy-key-numbers.component.html',
  styleUrls: ['./savings-policy-key-numbers.component.scss']
})
export class SavingsPolicyKeyNumbersComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
}
