import { Component, Input } from '@angular/core';
import { LinieElement } from 'ds-api';

@Component({
  selector: 'mitpfa-savings-policy-details-line-element',
  templateUrl: './savings-policy-details-line-element.component.html'
})
export class SavingsPolicyDetailsLineElementComponent {
  @Input() headerContentId: string;
  @Input() headerSubst: string | number | boolean;
  @Input() tooltipContentId: string;
  @Input() total: number;
  @Input() lineElements: Array<LinieElement>;
  @Input() lineElementSubst: string | number | boolean;
  @Input() resultLine: boolean;
  expanded: boolean;

  toggleExpanded(expanded: boolean): void {
    this.expanded = expanded;
  }
}
