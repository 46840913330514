import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SavingsTransferData } from './marketrate-transfers-popup.model';
import { MatSelectChange } from '@angular/material/select';
import { GlobalWorkingService } from '@pfaform';
import { OverfoerselDetaljer, SavingsStore } from 'ds-api';

@Component({
  selector: 'mitpfa-savings-policy-details-marketrate-transfers-popup',
  templateUrl: './marketrate-transfers-popup.component.html',
  styleUrls: ['./marketrate-transfers-popup.component.scss']
})
export class MarketrateTransfersPopupComponent implements OnInit, OnDestroy {
  private readonly unsubscribe = new Subject<void>();
  savingsTransferData: SavingsTransferData;
  transferDetails: OverfoerselDetaljer;
  chosenYear: number;
  initialized: boolean;
  noTransferDetails: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: SavingsTransferData,
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly savingsStore: SavingsStore,
    private readonly dialogRef: MatDialogRef<MarketrateTransfersPopupComponent>
  ) {
    this.savingsTransferData = data;
  }

  ngOnInit(): void {
    const startYear = this.savingsTransferData.transfer.aar.find(
      year => year === this.savingsTransferData.startYear
    );
    this.chosenYear = startYear ? startYear : new Date().getFullYear();
    this.loadDetails();
  }

  loadDetails(): void {
    this.savingsStore
      .opsparingOverfoerselValgtOverfoertAftaleIdValgtAarOverfoertAftaleTegningsDatoGet(
        this.savingsTransferData.transfer.policenummer,
        this.chosenYear,
        this.savingsTransferData.transfer.afgivendePoliceTegningDato
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (transferDetails: OverfoerselDetaljer) => {
          this.transferDetails = transferDetails;
          this.initialized = true;
          this.globalWorkingService.hide();
          this.noTransferDetails = false;
        },
        () => {
          this.noTransferDetails = true;
        }
      );
  }

  selectYear(event: MatSelectChange): void {
    this.globalWorkingService.show();
    this.chosenYear = event.value;
    this.loadDetails();
  }

  closePopup() {
    this.ngOnInit(); // Restore initial state for which the popup was triggered
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
